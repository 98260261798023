import "../styles/Artists.scss";

import React, { useContext } from "react";

import Footer from "../components/Footer.jsx";
import LocaleContext from "../context/LocaleContext.jsx";
import Navbar from "../components/Navbar.jsx";
import { graphql } from "gatsby";
import icon_fb from "../images/icon_fb.svg";
import icon_home from "../images/icon_home.svg";
import icon_insta from "../images/icon_insta.svg";

function Live({ data }) {
  const [locale] = useContext(LocaleContext);

  const artistDataTab = data.allContentfulArtiste.edges.filter(
    (artist) => artist.node.node_locale === locale
  );

  return (
    <>
      <Navbar currentPage="artists" />
      <div className="artists-container">
        {artistDataTab.map((artist, i) => {
          return (
            <div key={i} className="artistBlock">
              <img src={artist.node.photo.fluid.src} alt={artist.node.nom} />
              <div className="nom">{artist.node.nom}</div>
              {artist.node.instagram && (
                <div className="rsContainer">
                  <img src={icon_insta} alt="instagram" />
                  <a
                    href={artist.node.instagram}
                    target="_blank"
                    rel="noreferrer"
                  >
                    @
                    {
                      artist.node.instagram.split("/")[
                        artist.node.instagram.split("/").length - 1
                      ]
                    }
                  </a>
                </div>
              )}
              {artist.node.facebook && (
                <div className="rsContainer">
                  <img src={icon_fb} alt="facebook" />
                  <a
                    href={artist.node.facebook}
                    target="_blank"
                    rel="noreferrer"
                  >
                    /
                    {
                      artist.node.facebook.split("/")[
                        artist.node.facebook.split("/").length - 1
                      ]
                    }
                  </a>
                </div>
              )}
              {artist.node.siteWeb && (
                <div className="rsContainer">
                  <img src={icon_home} alt="site web" />
                  <a
                    href={artist.node.siteWeb}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Site web
                  </a>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <Footer />
    </>
  );
}
export const query = graphql`
  query ArtistQuery {
    allContentfulArtiste {
      edges {
        node {
          nom
          facebook
          instagram
          siteWeb
          contentful_id
          photo {
            fluid(sizes: "40px") {
              src
            }
          }
          node_locale
        }
      }
    }
  }
`;

export default Live;
